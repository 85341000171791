<template>
  <div class="max-w-6xl mx-auto" v-fade-in="!loading" >
    <div class="grid md:grid-cols-9 lg:grid-cols-9 gap-4 mt-4">
      
      <div class="md:col-span-3 lg:col-span-3 border border-dashed border-blue-400 order-2 sm:order-1 rounded bg-white">
        <div class="flex flex-col bg-gray-300 p-4 font-semibold text-lg">
          <span>{{ program.display_title }} <strong class="text-purple-500" v-if="program.admitted">({{program.admitted.program_group.name}})</strong> </span>
          <span v-if="program.batch"> Session : {{ program.batch.year }}</span>
        </div>
        <div class="text-lg font-semibold text-blue-500 p-2 border-b border-blue-300">Lecture Content</div>
        <div v-for="schedule in schedules" :key="schedule.id" class="px-2 py-2">
         <div class="w-full cursor-pointer hover:bg-gray-200 py-2 px-1 rounded  text-base font-semibold" @click="showItem(schedule.date)" :class="{'bg-gray-200 py-2' : lecturItemShow === schedule.date}" > {{ schedule.date }}</div>
         <div  v-if="lecturItemShow === schedule.date" class="translate-y-6 ml-1">
           <div v-for="item in schedule.schedules_items" :key="item.id" class="mb-4">
             <div v-if="Array.isArray(item.contents_materials) && item.contents_materials.length" class="px-2">
               <div class="italic">
                <div class="font-semibold text-base">{{ item.time }}</div>
                 <div>{{ item.subjectName }}</div>
               </div>
               <LectureItem class="ml-1" v-for="lecture in item.contents_materials" :key="lecture.id" :material="lecture" :mountedData="schedule.date"/>
             </div>
             <div v-else class="text-sm italic px-2">
               <span class="font-semibold"> {{ item.time }}</span> 
               <div>
                {{ item.subjectName }} : <span class="text-gray-300 ">no content available here</span>
               </div>
             </div>
 
           </div>
         </div>
        </div>
      </div>

      <div class="md:col-span-6 lg:col-span-6 relative bg-white order-1 sm:order-2" style="min-height: 500px;" >
        <overly class="col-span-full rounded w-full" background="bg-blue-100" v-if="loading" :loading="loading">
        </overly>

        <div v-if="lecture.resource_type === 'youtube'" class="plyr__video-embed aspect-w-16 aspect-h-9" id="player" >
          <iframe
            class="w-full"
            :src="lecture.embed_link"
            allowfullscreen
            allowtransparency
            allow="autoplay"
          ></iframe>
        </div>

        <div class="mt-4 grid gap-4 bg-white rounded p-2">
          <div v-if="lecture.resource_type === 'zoom' || lecture.resource_type === 'meet.google'" class="space-y-3">
            <div class="text-xl">{{ lecture.title }}</div>
            <LiveClass :lecture="lecture" />
          <div> {{ lecture.text }}</div>
          </div>
            
          <div v-else class="p-4 space-y-4" >
            <div class="text-xl border-b-2 border-blue-400">{{ lecture.title }}</div>
            <div>
              <span v-html="lecture.text"></span>
            </div>
            <a :href="lecture.link" class="hover:underline text-blue-400" target="__blank">Reference</a>
        </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script setup >

// import SvgParagraph from '@/components/svgs/svg-article.vue'
// import SvgLiveVideo from '@/components/svgs/live-video.vue'
// import SvgPlay from '@/components/svgs/svg-play.vue'

import LectureItem from './LectureItem.vue';
import LiveClass from './LiveClass.vue';
import Spinner from '@/components/spinner.vue';
import Overly from '@/components/overly.vue';

import { ref, onMounted, watch   } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
const route = useRoute() 
const loading = ref(false)
const schedules = ref([])
const lecture = ref({})
const program = ref({})
const lecturItemShow = ref({})
const store = useStore();

onMounted( () => {
  store.dispatch( 'batch/schedules', {
      params: {
        program_id: route.params.programId
      },

      success: ({data}) => {
        schedules.value = data.schedule;
        program.value = data.program;
      },

      error:(response) => { }

    } ).finally( () => loading.value = false )
})

onMounted( () => {
  showItem(route.query.date)
})

async function lectureCall() {

  loading.value = true;

  store.dispatch( 'batch/getLectureDetails', {
      params: {
        lectureId: route.params.lectureId
      },

      success: ({data}) => {
        lecture.value = data;
      },

      error:(response) => { }

    } ).finally( () => loading.value = false )
}

async function showItem(date){
  const currentValue = lecturItemShow.value;

  if (currentValue === date) {
    lecturItemShow.value = ''; 
  } else {
    lecturItemShow.value = date;
  }
}

// const IconComponent = computed( () => {
//   switch(((props.material || {}).icon || '')){
//     case 'video-icon':
//       return SvgPlay
//     case 'live-class-icon':
//       return SvgLiveVideo
//     case 'paragraph-icon':
//       return SvgParagraph
//     default:
//       return ''
//   }
// })

onMounted( () => {
  lectureCall()
})

watch( () => route.params.lectureId , async (newQuestion, oldQuestion) => {
  lectureCall()
})

</script>